.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust the height as needed */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-info a,
.footer-info p {
  color: #fff;
  display: flex;
  align-items: center;
  margin: 5px 0;
  text-decoration: none;
}

.footer-info a:hover {
  text-decoration: underline;
}

.footer-icon {
  margin-right: 10px;
}
