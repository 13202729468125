/* Header.css */

header {
  background-color: var(--header-bg-color);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 200px;
  height: auto;
}

nav {
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.nav-links a:hover {
  text-decoration: underline;
}
