@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&family=Roboto:wght@300;400&display=swap");

body {
  background-color: #f9f9f9;
  font-family: "Roboto", sans-serif;
  color: #333;
  margin: 0;
}

.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu-container h1 {
  font-family: "Playfair Display", serif;
  font-size: 2.5em;
  font-weight: 400; /* Adjusted font weight */
  margin-bottom: 20px;
}

.menu-section {
  margin-bottom: 40px;
  width: 80%;
}

.menu-section h2 {
  font-family: "Playfair Display", serif;
  font-size: 1.8em;
  font-weight: 400; /* Adjusted font weight */
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.menu-text {
  flex: 1;
  margin-right: 20px;
}

.menu-text h3 {
  font-size: 1.2em;
  font-weight: 400; /* Adjusted font weight */
  margin: 0;
}

.menu-text p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #555;
}

.menu-text span {
  font-weight: 400; /* Adjusted font weight */
  color: #333;
}

.menu-image {
  flex-shrink: 0;
  width: 150px;
  height: 150px;
}

.menu-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.set-menu-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.set-menu-image {
  flex-shrink: 0;
  width: 300px;
  height: 300px;
  margin-left: 20px;
  margin-top: 80px;
}

.set-menu-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dessert-options {
  margin-left: 20px;
  list-style: none;
  padding: 0;
}

.or-divider {
  font-weight: bold;
  margin: 10px 0;
}

.dessert-options li:first-child::before {
  content: none;
}

.set-menu-header,
.dessert-header,
.smaller-portions-header {
  font-weight: 400; /* Adjusted font weight */
}
