/* AboutUs.css */

.main-container {
  display: flex;
  text-align: center;
  color: rgb(255, 255, 255);
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.image-container {
  width: 100%;
  position: relative;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional for better text readability */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.overlay h1 {
  font-size: 3rem;
  margin: 0;
}

.cta-button {
  background-color: #a90000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #820000;
}

.opening-hours {
  width: 50%;
  background-color: white;
  color: black;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  text-align: center;
}

.opening-hours h2 {
  margin-top: 0;
}

.opening-hours p {
  margin: 5px 0;
}
