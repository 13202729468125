:root {
  --header-height: 60px;
  --footer-height: 60px;
  --header-bg-color: #262626;
  --footer-bg-color: #262626;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: scroll; /* Always show scrollbar */
}
